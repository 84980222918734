<template>
  <b-modal ref="modalCourseDetail" centered hide-footer :title="selectedCourse ? selectedCourse.category : ''" size="xl" @hidden="modalClosed">
    <div v-if="selectedCourse">
      <div :style="'background: url(\'/img/' + selectedCourse.background + '\')'" class="course-background">
        <!-- <b-img :src="'/img/' + selectedCourse.background" fluid alt="Responsive image"></b-img> -->
      </div>
      <div class="h5 mt-4 text-bold"><em><strong>{{ selectedCourse.name }}</strong></em></div>
      <dl class="row">
        <dt class="col-sm-3">Quando</dt>
        <dd class="col-sm-9">{{ selectedCourse.periodText }}</dd>

        <dt class="col-sm-3">Orario</dt>
        <dd class="col-sm-9">{{ selectedCourse.hour }}</dd>

        <dt class="col-sm-3">Tenuto da</dt>
        <dd class="col-sm-9">{{ selectedCourse.who }}</dd>

        <dt class="col-sm-3">Dove</dt>
        <dd class="col-sm-9">{{ selectedCourse.location }}</dd>

        <dt class="col-sm-3">Prezzo</dt>
        <dd class="col-sm-9">{{ selectedCourse.price }}<span v-if="selectedCourse.totalPrice"> per il singolo incontro o {{ selectedCourse.totalPrice }} per tutti</span></dd>
      </dl>
      <div class="mt-4">{{ selectedCourse.description }}</div>
    </div>
    <div class="mt-4 text-right">
      <b-button class="btn btn-sm"
        @click="subscribeActivity">Mi voglio iscrivere</b-button>
    </div>
    </b-modal>
</template>
<script>
export default {
  name: 'activity-detail',
  bodyClass: '',
  props: ['selectedCourse'],
  components: {
  },
  data() {
    return {
      subscribe: {
        text: ""
      }
    }
  },
  methods: {
    openActivityDetail: function() {
      this.$func.openModal(this, 'modalCourseDetail');
    },
    modalClosed: function() {
      this.$emit('modal-closed');
    },
    subscribeActivity: function() {
      var text = 'Buongiorno, vorrei iscrivermi al corso "' + this.selectedCourse.name;
      text += '" tenuto da ' + this.selectedCourse.who;
      text += ', che si svolgerà ' + this.selectedCourse.periodText.toLowerCase();
      if (this.selectedCourse.price)
        text += ', al costo di ' + this.selectedCourse.price;
      if (this.selectedCourse.totalPrice)
        text += " per signolo appuntamento o di " +  this.selectedCourse.totalPrice + " complessivi";
      text += '.';
      
      this.subscribe.text = text;
      this.$store.commit("setContact", this.subscribe);
      this.$func.closeModal(this, 'modalCourseDetail');
      this.$router.push({name: 'contact'});
    }
  },
  created() {
  }
};
</script>
<style></style>

import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Team from './pages/Team.vue';
import Who from './pages/Who.vue';
import Activity from './pages/Activity.vue';
import Login from './pages/Login.vue';
import Contact from './pages/Contact.vue';
import Manage from './pages/admin/Manage.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import store from './store'

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/team',
      name: 'team',
      components: { default: Team, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 85 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/attivita/:id?',
      name: 'activity',
      components: { default: Activity, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 85 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/chi-siamo',
      name: 'who',
      components: { default: Who, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 85 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 85 }
      }
    },
    {
      path: '/contattaci',
      name: 'contact',
      components: { default: Contact, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 85 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/admin/gestione',
      name: 'manage',
      components: { default: Manage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 85 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;

router.beforeEach((to, from, next)=>{
  if (to.name != 'login')
    store.commit('setFrom', to.name);
  
  if (to.name != 'contact' && store.state.contact.text)
    store.commit('setContact', { text: "" });
    
  if ( to.name === 'manage' && store.state.role != 'administrator' ){
    next({
      name: 'login',
      replace: true
    })
  } else {
    next();
  }
})

<template>
  <b-modal ref="modalTeamDetail" id="team-modal" centered hide-footer :title="teamDetail ? teamDetail.name : ''" size="lg" @hidden="modalClosed">
    <div v-if="teamDetail">
      <b-card v-if="mobile"
        :img-src="teamDetail.image.data ? backendURL + teamDetail.image.data.attributes.url : 'img/default-avatar.png'"
        img-width="300px"
        img-height="300px"
        img-top
      >
        <b-card-text><p class="category text-secondary">{{ teamDetail.role }}</p>
          {{ teamDetail.description }}
        </b-card-text>
      </b-card>
      <b-card v-else
        :img-src="teamDetail.image.data ? backendURL + teamDetail.image.data.attributes.url : 'img/default-avatar.png'"
        img-width="300px"
        img-height="300px"
        img-left
      >
        <b-card-text><p class="category text-secondary">{{ teamDetail.role }}</p>
          {{ teamDetail.description }}
        </b-card-text>
      </b-card>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'activity-detail',
  bodyClass: '',
  props: ['teamDetail'],
  components: {
  },
  data() {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      mobile: false
    }
  },
  methods: {
    openTeamDetail: function() {
      this.$func.openModal(this, 'modalTeamDetail');
    },
    modalClosed: function() {
      this.$emit('modal-closed');
    }
  },
  created() {
    this.mobile = window.innerWidth <= 991;
  }
};
</script>
<style>
#team-modal .card-img-top {
  width: 300px;
  align-self: center;
}
</style>

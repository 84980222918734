<template>
  <div style="min-height: calc(100vh - 72px);">
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('../img/main.jpg')"
      >
      </parallax>
    </div>
    <div class="section-team text-center">
      <div class="container mt-5 mb-5">
        <h2>Gestione team e attività</h2>
        <b-row>
          <b-col>
            <b-button class="btn" block pill size="lg" @click="showManageTeam = true; showManageActivity = false">Team</b-button>
          </b-col>
          <b-col>
            <b-button class="btn" block pill size="lg" @click="showManageTeam = false; showManageActivity = true">Attività</b-button>
          </b-col>
        </b-row>
        <b-row v-if="showManageTeam">
          <ManageTeam/>
        </b-row>
        <b-row v-if="showManageActivity">
          <ManageActivity/>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import ManageActivity from "./ManageActivity.vue";
import ManageTeam from "./ManageTeam.vue";
export default {
  name: 'manage',
  bodyClass: 'profile-page',
  components: {
    ManageTeam,
    ManageActivity
  },
  data() {
    return {
      showManageTeam: false,
      showManageActivity: false
    }
  },
  methods: {
    
  }
};
</script>
<style></style>

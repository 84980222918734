import { Months } from '../plugins/const.js';
import { ActivityCategory } from '../plugins/const.js';

export const func = {
  openModal: function (componentThis, modal) {
    componentThis.$refs[modal].show();
  },
  closeModal: function (componentThis, modal) {
    componentThis.$refs[modal].hide();
  },
  getMonth: (startDate) => {
    return startDate.substring(4,6);
  },
  getYear: (startDate) => {
    return startDate.substring(0,4);
  },
  getCoursePeriod: function(course, text) {
    if (course.startDate != course.endDate) {
      var startMonth = "";
      var endMonth = "";
      if ((this.getMonth(course.startDate) != this.getMonth(course.endDate)) || text) {
        startMonth = " " + Months[this.getMonth(course.startDate)];
        endMonth = " " + Months[this.getMonth(course.endDate)];
      }
      if (text)
        return course.periodicity + ", " + "dal " + course.startDate.substring(6,8) + startMonth + " al " + course.endDate.substring(6,8) + endMonth;

      return course.startDate.substring(6,8) + startMonth + " - " + course.endDate.substring(6,8) + endMonth;
    }
    else {
      if (text)
        return "il " + course.startDate.substring(6,8) + " " + Months[this.getMonth(course.startDate)];

      return course.startDate.substring(6,8);
    }
  },
  buildCourses: function(courses) {
    var displayCourses = [];
    var month = "";
    for (var i=0; i<courses.length; i++) {
      var course = courses[i].attributes;
      var courseDisplay = {
        id: "",
        type: "",
        month: "",
        toShowMonth: false,
        year: "",
        period: "",
        periodText: "",
        hour: "",
        name: "",
        category: "",
        who: "",
        location: "",
        description: "",
        background: "",
        poster: "",
        teams: []
      };
      courseDisplay.id = courses[i].id;
      courseDisplay.month = this.getMonth(course.startDate);
      courseDisplay.year = this.getYear(course.startDate);
      if (this.getMonth(course.startDate) != month) {
        month = courseDisplay.month;
        courseDisplay.toShowMonth = true;
      }
      courseDisplay.period = this.getCoursePeriod(course, false);
      if (course.startDate != course.endDate) {
        courseDisplay.type = "Corso";
      } else {
        courseDisplay.type = "Evento";
      }
      courseDisplay.periodText = this.getCoursePeriod(course, true);
      courseDisplay.hour = "dalle " + course.startTime + " alle " + course.endTime;
      courseDisplay.name = course.name;
      courseDisplay.category = this.getCategoryDescription(course.category);
      if (course.teams && course.teams.data) {
        var teams = "";
        for (var j=0; j<course.teams.data.length; j++) {
          if (j>0) 
            teams += ", ";
          teams += course.teams.data[j].attributes.name;
          courseDisplay.teams.push({ id: course.teams.data[j].id, name: course.teams.data[j].attributes.name });
        }
        courseDisplay.who = teams;
      }
      const activityBackground = [
        { value: "CREATIVITA", background: "creatività.jpg" },
        { value: "MENTE", background: "mente.jpg" },
        { value: "NATURA", background: "natura.jpg" },
        { value: "FEMMINILE", background: "femminile.jpg" },
      ];
      if (course.category) {
        const index = activityBackground.findIndex(item => item.value === course.category);
        if (index >= 0)
          courseDisplay.background = activityBackground[index].background;
      }
      //courseDisplay.who = course.who;
      courseDisplay.location = course.location;
      courseDisplay.description = course.description;
      courseDisplay.poster = course.poster;
      courseDisplay.price = course.price;
      courseDisplay.totalPrice = course.totalPrice;
      displayCourses.push(courseDisplay);
    }
    return displayCourses;
  },
  getTodayDateString: function() {
    return this.formatDateToDBDate(new Date());
  },
  formatDateToDBDate: function(date) {
    if (date) {
      var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
      var dd = date.getDate().toString();

      return [date.getFullYear(), mm.length===2 ? '' : '0', mm, dd.length===2 ? '' : '0', dd].join(''); // padding
    }
    return null;
  },
  formatDBDateToString: function(date) {
    if (date) {
      if (date.length == 8)
        return date.substring(6,8) + "/" + date.substring(4,6) + "/" + date.substring(0,4);
      else if (date.length == 6)
        return date.substring(4,6) + "/" + date.substring(0,4);
      else
        return date;
    }
    return null;
  },
  formatDBDateToDate: function(date) {
    if (date)
      return date.substring(0,4) + "-" + date.substring(4,6) + "-" + date.substring(6,8);
    return null;
  },
  formatDatepickerToDBDate: function(date) {
    if (date)
      return "" + date.substring(0,4) + date.substring(5,7) + date.substring(8,10);
    return null;
  },
  getCategoryDescription: function(category) {
    const index = ActivityCategory.findIndex(item => item.value === category);
    if (index >= 0)
      return ActivityCategory[index].text;

    return "";
  }
}
<template>
  <div>
    <b-table :class="!showTable ? 'd-none' : ''" hover id="course-table" ref="courseTable" class="table-responsive-xl"
      :items="displayCourses"
      :fields="courseFields"
      :filter="activityFilter"
      :filter-included-fields="filterOn"
      :filter-function="filterTable"
      tbody-tr-class="courses-tr"
      fixed
      borderless
      striped 
      primary-key="id"
      @filtered="onActivityFiltered">
      <template v-slot:cell(month)="data">
        <!-- <div style="position: relative;">
          <span v-if="data.item.period.indexOf('-') > 0" class="badge diagonal">Corso</span>
          <span v-else class="badge diagonal">Evento</span>
        </div> -->
        <div class="ribbon"><span :class="data.item.type == 'Corso' ? 'ribbon-green' : 'ribbon-red'">{{ data.item.type }}</span></div>
        <div v-if="data.item.toShowMonth" style="font-size: 1.2rem">{{ months[data.item.month] }}</div>
        <div v-if="data.item.toShowMonth" style="font-size: 1.6rem">{{ data.item.year }}</div>
      </template>
      <template v-slot:cell(who)="data">
        <div v-for="team in data.item.teams" :key="team.id" style="text-decoration: underline;" role="button" 
          @click="openTeamDetail(team.id)">{{ team.name }}</div>
      </template>
      <!-- <template v-slot:cell(period)="data">
        {{ $func.getCoursePeriod(data.item.startDate, data.item.endDate) }}
      </template> -->
      <template v-slot:cell(details)="data">
        <div>
          <b-button class="btn btn-sm"
            @click="openCourseDetail(data.item)">Dettagli</b-button>
        </div>
        <div>
          <b-button v-if="data.item.poster.data" class="btn btn-sm"
            @click="showPoster(data.item.poster.data)">Locandina</b-button>
          </div>
      </template>
    </b-table>
    <b-alert v-if="!showTable" show variant="info" class="mt-5">Non ci sono attività per i filtri selezionati</b-alert>

    <ActivityDetail ref="activityDetail"
      :selectedCourse="selectedCourse"
      @modal-closed="selectedCourse = null"/>
    <TeamDetail ref="teamDetail"
      :teamDetail="teamDetail"
      @modal-closed="teamDetail = null"/>

    <b-modal ref="modalPoster" centered hide-footer title="Locandina" size="auto">
      <b-img
        :src="backendURL + posterURL"
        class="img-raised"
      />
    </b-modal>
  </div>
</template>
<script>
import ActivityDetail from "./ActivityDetail.vue";
import TeamDetail from "./TeamDetail.vue";
import http from "../http-api";
import { Months } from '../plugins/const.js';
export default {
  name: 'activity-table',
  bodyClass: '',
  props: ['displayCourses','activityFilter','filterOn'],
  components: {
    ActivityDetail,
    TeamDetail
  },
  data() {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      posterURL: null,
      months: Months,
      courseFields: [
        { key: "month", label: "", thStyle: "width: 160px", thClass: "text-center", tdClass: 'text-center align-middle ribbon-container' },
        { key: "period", label: "Quando", tdClass: 'align-middle' },
        { key: "name", label: "Attività", tdClass: 'align-middle' },
        { key: "who", label: "Tenuto da", tdClass: 'align-middle' },
        { key: "location", label: "Dove", tdClass: 'align-middle' },
        { key: "details", label: "", tdClass: 'text-center align-middle', thStyle: "width: 140px"}
      ],
      selectedCourse: null,
      teamDetail: null,
      showTable: true
    }
  },
  methods: {
    openCourseDetail: function(course) {
      this.selectedCourse = course;
      this.$refs.activityDetail.openActivityDetail();
      //this.$func.openModal(this, 'modalCourseDetail');
    },
    openTeamDetail: function(id) {
      http.get('/api/teams/' + id + '?populate=*').then(response => {
        this.teamDetail = response.data.data.attributes;
      });
      this.$refs.teamDetail.openTeamDetail();
      //this.$func.openModal(this, 'modalTeamDetail');
    },
    showPoster: function(data) {
      this.posterURL = data.attributes.url;
      this.$func.openModal(this, 'modalPoster');
    },
    onActivityFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      /*if (filteredItems.length != this.formTotalRows) {
        this.selectableItems = filteredItems;
        this.formTotalRows = filteredItems.length;
        this.formCurrentPage = 1;
      }
      this.tableBusy = false;
      this.headIndeterminate = false;
      this.headChecked = false;
      this.selectedForms = [];
      for (var i=0; i<this.selectableItems.length; i++) {
        var item = this.selectableItems[i];
        item.selected = false;
      }*/
      if (filteredItems.length > 0)
        this.showTable = true;
      else
        this.showTable = false;

      var month = "";
      for (var i=0; i<filteredItems.length; i++) {
        var course = filteredItems[i];
        if (course.month != month) {
          month = course.month;
          course.toShowMonth = true;
        } else {
          course.toShowMonth = false;
        }
      }
    },
    filterTable: function (row, filter) {
      var includedWho = false, includedType = false, includedCategory = false, includedMonth = false, includedWhere = false;
      if (filter.who.length == 0 && filter.category.length == 0 && filter.type.length == 0 && filter.month.length == 0 && filter.where.length == 0) {
        return true;
      } else {
        if (filter.type.length > 0) {
          for (var i=0; i<filter.type.length; i++) {
            var type = filter.type[i];
            if (row.type && row.type.toUpperCase().indexOf(type.toUpperCase()) > -1) {
              includedType = true;
            }
          }
        }
        if (filter.month.length > 0) {
          for (var i=0; i<filter.month.length; i++) {
            var month = filter.month[i];
            if (row.month && row.month.toUpperCase().indexOf(month.toUpperCase()) > -1) {
              includedMonth = true;
            }
          }
        }
        if (filter.who.length > 0) {
          for (var i=0; i<filter.who.length; i++) {
            var who = filter.who[i];
            if (row.who && row.who.toUpperCase().indexOf(who.toUpperCase()) > -1) {
              includedWho = true;
            }
          }
        }
        if (filter.category.length > 0) {
          for (var i=0; i<filter.category.length; i++) {
            var category = filter.category[i];
            if (row.category && row.category.toUpperCase().indexOf(category.toUpperCase()) > -1) {
              includedCategory = true;
            }
          }
        }
        if (filter.where.length > 0) {
          for (var i=0; i<filter.where.length; i++) {
            var where = filter.where[i];
            if (where == "ONLINE") {
              if (row.location && row.location.toUpperCase().indexOf(where.toUpperCase()) > -1) {
                includedWhere = true;
              }
            } else {
              where = "ONLINE";
              if (row.location && row.location.toUpperCase().indexOf(where.toUpperCase()) < 0) {
                includedWhere = true;
              }
            }
          }
        }
        if ((includedType || filter.type.length == 0) &&
          (includedWho || filter.who.length == 0) &&
          (includedCategory || filter.category.length == 0) &&
          (includedWhere || filter.where.length == 0) &&
          (includedMonth || filter.month.length == 0))
          return true;
        else
          return false;
      }
    }
  },
  created() {
  }
};
</script>
<style>
.ribbon-container {
  position: relative;
}
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
.ribbon-green {
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
}
.ribbon-red {
  background: #F70505;
  background: linear-gradient(#F70505 0%, #8F0808 100%);
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
</style>

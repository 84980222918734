<template>
  <b-col>
    <b-alert class="top-alert text-left" :variant="alert.variant" fade dismissible :show="dismissCountDown" 
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged">{{ alert.message }}
      <b-progress
        :variant="alert.variant"
        :max="alert.dismissSecs"
        :value="dismissCountDown"
        height="1px"
      ></b-progress>
    </b-alert>

    <b-row class="text-left">
      <b-col>
        <h3 class="text-center mt-4">Gestione Team</h3>
        <b-button class="btn" pill size="sm" @click="addTeam">Aggiungi un membro del team</b-button>
        <b-table hover id="admin-table" ref="adminTable"
          :items="tableResult"
          :fields="teamFields"
          selectable
          select-mode="single"
          fixed
          borderless
          striped
          @row-selected="rowSelected">
        </b-table>
        <b-row style="margin-right: -12px">
          <b-col>
            <b-form-group
              label="Per pagina"
              label-for="per-page-select"
              label-cols-sm="4"
              label-cols-md="3"
              label-cols-lg="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                style="width: 100px;"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="tableResult.length"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="teamDetail.id || isAdd" class="mt-4">
      <b-col class="text-left">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Nome"
          label-for="team-name"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="team-name" v-model="teamDetail.name"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Ruolo"
          label-for="team-role"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="team-role" v-model="teamDetail.role"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Descrizione"
          label-for="team-description"
          label-size="sm"
        >
          <b-form-textarea :disabled="disabled" rows="3" id="team-description" v-model="teamDetail.description"></b-form-textarea>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Foto"
          label-size="sm"
        >
          <!-- <b-form-file v-if = "showUploadPicture" class="text-left" :disabled="disabled" size="sm" 
            placeholder="Seleziona un file..."
            accept="image/jpeg, image/png" 
            v-model="teamDetail.picture"></b-form-file>
          <span v-else>
            <b-img
              :src="backendURL + teamDetail.picture.attributes.url"
              height="100px"
              width="100px"
              class="img-raised"
            />
            <b-icon v-if="!disabled" icon="x-circle-fill" variant="danger" class="h4" 
              style="position: absolute; top: -29px; left: 80px; cursor: pointer; background: white; border-radius: 60%;" 
              @click="deleteImage = true; showUploadPicture = true; teamDetail.picture = null;"></b-icon>
          </span> -->
          <Picture :previewWidth="300" :previewHeight="300" :url="teamDetail.picture" :id="1" :minWidth="300" :minHeight="300" :disabled="disabled || !teamDetail.name"
            @image-cropped="(tmpFile) => {teamDetail.picture=tmpFile}"
            @delete-image="deleteImage = true; showUploadPicture = true; teamDetail.picture = null;"
            @open-alert="onOpenAlert"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-left">
        <span v-if="isAdd">
          <b-button class="btn mr-3" pill size="sm" @click="insertTeam">Salva</b-button>
          <b-button class="btn" pill size="sm" @click="teamDetail = JSON.parse(JSON.stringify(emptyTeamDetail)); $refs.adminTable.clearSelected(); disabled = true; isAdd = false">Annulla</b-button>
        </span>
        <span v-if="teamDetail.id">
          <span v-if="!isModify && !isAdd">
            <b-row>
              <b-col>
                <b-button class="btn" pill size="sm" @click="modifyTeam">Modifica</b-button>
              </b-col>
              <b-col class="text-right">
                <b-button class="btn" variant="danger" pill size="sm" @click="openDeleteTeam()">Elimina</b-button>
              </b-col>
            </b-row>
          </span>
          <span v-if="isModify">
            <b-button class="btn mr-3" pill size="sm" @click="saveTeam">Salva</b-button>
            <b-button class="btn" pill size="sm" @click="cancelModifyTeam">Annulla</b-button>
          </span>
        </span>
      </b-col>
    </b-row>

    <b-modal ref="deleteTeam" centered hide-footer title="Elimina membro del team" size="md">
      <b-row class="text-center mb-3"><b-col><span class="content-label">Sei sicuro di voler eliminare il membro del team?</span></b-col></b-row>
      <b-row class="text-center">
        <b-col>
          <b-button class="btn btn-dark btn-sm mr-3" @click="confirmDeleteTeam()">Elimina</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-col>
</template>
<script>
import Picture from "./Picture.vue";
import http from "../../http-api";
export default {
  name: 'inset',
  bodyClass: 'profile-page',
  components: {
    Picture
  },
  data() {
    return {
      tableResult: [],
      teamFields: [
        { key: "name", label: "Nome", tdClass: 'align-middle' },
        { key: "role", label: "Ruolo", tdClass: 'align-middle' }
      ],
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      teamDetail: {
        id: null
      },
      emptyTeamDetail: {
        id: null,
        name: null,
        role: null,
        description: null,
        picture: null
      },
      disabled: true,
      teamBackup: null,
      showUploadPicture: true,
      backendURL: process.env.VUE_APP_BACKEND_URL,
      isModify: false,
      isAdd: false,
      deleteImage: false,
      alert: {
        variant: '',
        message: '',
        dismissSecs: 5
      },
      dismissCountDown: 0
    }
  },
  mounted() {
    this.manageTeam();
  },
  methods: {
    manageTeam: function() {
      http.get('/api/teams?populate=*').then(response => {
        this.buildTeamsTable(response.data.data);
      });
    },
    buildTeamsTable: function(data) {
      this.tableResult = [];
      for (var i=0; i<data.length; i++) {
        var teamDetail = JSON.parse(JSON.stringify(this.emptyTeamDetail));
        teamDetail.id = data[i].id;
        teamDetail.name = data[i].attributes.name;
        teamDetail.role = data[i].attributes.role;
        teamDetail.description = data[i].attributes.description;
        teamDetail.picture = data[i].attributes.image.data;
        this.tableResult.push(teamDetail);
      }
    },
    rowSelected: function (items) {
      if (items.length > 0) {
        this.teamDetail = items[0];
        if (this.teamDetail.picture)
          this.showUploadPicture = false;
        else
          this.showUploadPicture = true;
      } else {
        this.teamDetail = JSON.parse(JSON.stringify(this.emptyTeamDetail));
      }
    },
    addTeam: function() {
      this.isAdd = true;
      this.$refs.adminTable.clearSelected();
      this.disabled = false;
      this.teamDetail = JSON.parse(JSON.stringify(this.emptyTeamDetail));
      this.showUploadPicture = true;
    },
    insertTeam: function() {
      var team = {
        data: {
          name: this.teamDetail.name,
          role: this.teamDetail.role,
          description: this.teamDetail.description
        }
      }
      if (this.teamDetail.picture) {
        const fileName = this.teamDetail.name.toLowerCase().replace(" ","_");
        // generate file from base64 string
        const file = this.dataURLtoFile(this.teamDetail.picture, fileName);
        const formData = new FormData();
        formData.append('files', file);
        //formData.append('files', this.teamDetail.picture);
        http.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })
        .then(response => {
          team.data.image = response.data[0];
          http.post('/api/teams', JSON.stringify(team), {
            headers: {
              'Authorization': `Bearer ${this.$store.state.token}`,
            }
          }).then(response => {
            this.isAdd = false;
            this.manageTeam();
          });
        })
        .catch(error => {
          // Handle error
        });
      } else {
        http.post('/api/teams', JSON.stringify(team), {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          this.isAdd = false;
          this.manageTeam();
        });
      }
    },
    modifyTeam: function() {
      this.teamBackup = JSON.parse(JSON.stringify(this.teamDetail));
      this.disabled = false;
      this.isModify = true;
    },
    cancelModifyTeam: function() {
      this.teamDetail.name = this.teamBackup.name;
      this.teamDetail.role = this.teamBackup.role;
      this.teamDetail.description = this.teamBackup.description;
      this.teamDetail.picture = this.teamBackup.picture;
      this.disabled = true;
      this.isModify = false;
      if (this.deleteImage)
        this.showUploadPicture = false;
      
      this.deleteImage = false;
    },
    saveTeam: function() {
      var team = {
        data: {
          name: this.teamDetail.name,
          role: this.teamDetail.role,
          description: this.teamDetail.description
        }
      }
      if (this.showUploadPicture && this.teamDetail.picture) {
        /*const formData = new FormData();
        formData.append('files', this.teamDetail.picture);
        http.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })
        const dataURLtoFile = (dataurl, filename) => {
          const arr = dataurl.split(',')
          const mime = arr[0].match(/:(.*?);/)[1]
          const bstr = atob(arr[1])
          let n = bstr.length
          const u8arr = new Uint8Array(n)
          while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
          }
          return new File([u8arr], filename, { type: mime })
        }*/

        const fileName = this.teamDetail.name.toLowerCase().replace(" ","_");
        // generate file from base64 string
        const file = this.dataURLtoFile(this.teamDetail.picture, fileName);
        /*const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', 'prova.jpg');
        formData.append('mime', 'image/jpeg');
        formData.append('ext', '.jpg');
        http.post('/api/teams/uploadCroppedImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })*/
        const formData = new FormData();
        formData.append('files', file);
        http.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })
        .then(response => {
          this.showUploadPicture = false;
          this.teamDetail.picture = {
            attributes: response.data[0],
            id: response.data[0].id
          }
          team.data.image = response.data[0];
          http.put('/api/teams/' + this.teamDetail.id, JSON.stringify(team), {
            headers: {
              'Authorization': `Bearer ${this.$store.state.token}`,
            }
          }).then(response => {
            this.isModify = false;
          });
        })
        .catch(error => {
          // Handle error
        });
      } else {
        http.put('/api/teams/' + this.teamDetail.id, JSON.stringify(team), {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          this.isModify = false;
        });
      }
      if (this.deleteImage) {
        http.delete('/api/upload/files/' + this.teamBackup.picture.id, {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          //console.log(response.data);
        });
      }
      this.disabled = true;
      this.deleteImage = false;
    },
    openDeleteTeam: function() {
      this.$func.openModal(this, 'deleteTeam');
    },
    confirmDeleteTeam: function() {
      this.$func.closeModal(this, 'deleteTeam');
      http.delete('/api/teams/' + this.teamDetail.id, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`,
        }
      }).then(response => {
        this.$refs.adminTable.clearSelected();
        this.manageTeam();
      });
    },
    dataURLtoFile: function(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    },
    onOpenAlert: function(alert) {
      this.alert = alert;
      this.dismissCountDown = this.alert.dismissSecs;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  }
};
</script>
<style></style>

<template>
  <b-col>
    <b-row class="text-left">
      <b-col>
        <h3 class="text-center mt-4">Gestione Attività</h3>
        <b-button class="btn" pill size="sm" @click="addActivity">Aggiungi un'attività</b-button>
        <b-table hover id="admin-table" ref="adminTable" class="table-responsive-xl"
          :items="tableResult"
          :fields="activityFields"
          selectable
          select-mode="single"
          fixed
          borderless
          striped
          @row-selected="rowSelected">
          <template v-slot:cell(startDate)="data">
            {{ $func.formatDBDateToString(data.item.startDate) }}
          </template>
          <template v-slot:cell(endDate)="data">
            {{ $func.formatDBDateToString(data.item.endDate) }}
          </template>
          <template v-slot:cell(category)="data">
            {{ $func.getCategoryDescription(data.item.category) }}
          </template>
        </b-table>
        <b-row style="margin-right: -12px">
          <b-col>
            <b-form-group
              label="Per pagina"
              label-for="per-page-select"
              label-cols-sm="4"
              label-cols-md="3"
              label-cols-lg="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                style="width: 100px;"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="tableResult.length"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="activityDetail.id || isAdd" class="mt-4">
      <b-col class="text-left">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Nome"
          label-for="activity-name"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-name" v-model="activityDetail.name"></b-form-input>
        </b-form-group>
        <!-- <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Data inzio"
          label-for="activity-start-date"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-start-date" v-model="activityDetail.startDate"></b-form-input>
        </b-form-group> -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Data inzio"
          label-for="activity-start-date"
          label-size="sm"
        >
          <b-form-datepicker
            id="activity-start-date"
            :disabled="disabled"
            size="sm"
            v-model="modifiedDate.startDate"
            locale="it-IT"
            hide-header
            show-decade-nav
            :label-help="labelHelp"
            :label-current-month="labelCurrentMonth"
            :label-next-month="labelNextMonth"
            :label-next-decade="labelNextDecade"
            :label-next-year="labelNextYear"
            :label-prev-decade="labelPrevDecade"
            :label-prev-month="labelPrevMonth"
            :label-prev-year="labelPrevYear"
            :label-today-button="labelTodayButton"
            :label-reset-button="labelResetButton"
            reset-button
            today-button
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            placeholder="Nessuna data selezionata"
          ></b-form-datepicker>
        </b-form-group>
        <!-- <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Data fine"
          label-for="activity-end-date"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-end-date" v-model="activityDetail.endDate"></b-form-input>
        </b-form-group> -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Data fine"
          label-for="activity-end-date"
          label-size="sm"
        >
          <b-form-datepicker
            id="activity-end-date"
            :disabled="disabled"
            size="sm"
            v-model="modifiedDate.endDate"
            locale="it-IT"
            hide-header
            show-decade-nav
            :label-help="labelHelp"
            :label-current-month="labelCurrentMonth"
            :label-next-month="labelNextMonth"
            :label-next-decade="labelNextDecade"
            :label-next-year="labelNextYear"
            :label-prev-decade="labelPrevDecade"
            :label-prev-month="labelPrevMonth"
            :label-prev-year="labelPrevYear"
            :label-today-button="labelTodayButton"
            :label-reset-button="labelResetButton"
            reset-button
            today-button
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            placeholder="Nessuna data selezionata"
          ></b-form-datepicker>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Periodicità"
          label-for="activity-periodicity"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-periodicity" v-model="activityDetail.periodicity"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Ora inizio"
          label-for="activity-start-time"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" placeholder="HH:mm" id="activity-start-time" v-model="activityDetail.startTime"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Ora fine"
          label-for="activity-end-time"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" placeholder="HH:mm" id="activity-end-time" v-model="activityDetail.endTime"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Categoria"
          label-for="activity-category"
          label-size="sm"
        >
          <!-- <b-form-input :disabled="disabled" size="sm" id="activity-category" v-model="activityDetail.category"></b-form-input> -->
          <b-form-select :disabled="disabled" id="activity-category"
            v-model="activityDetail.category" :options="activityCategory" size="sm" class="mb-2">
            <template #first>
              <b-form-select-option :value="null">Seleziona...</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Luogo"
          label-for="activity-location"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-location" v-model="activityDetail.location"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Prezzo singolo"
          label-for="activity-price"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-price" v-model="activityDetail.price"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Prezzo totale"
          label-for="activity-total-price"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-total-price" v-model="activityDetail.totalPrice"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Descrizione"
          label-for="activity-description"
          label-size="sm"
        >
          <b-form-textarea :disabled="disabled" rows="3" id="activity-description" v-model="activityDetail.description"></b-form-textarea>
        </b-form-group>
        <!-- <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Foto"
          label-for="activity-background"
          label-size="sm"
        >
          <b-form-input :disabled="disabled" size="sm" id="activity-background" v-model="activityDetail.background"></b-form-input>
        </b-form-group> -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Poster"
          label-size="sm"
        >
          <b-form-file v-if = "showUploadPoster" class="text-left" :disabled="disabled" size="sm" 
            placeholder="Seleziona un file..."
            accept="image/jpeg, image/png" 
            v-model="activityDetail.poster"></b-form-file>
          <span v-else>
            <b-img
              :src="backendURL + activityDetail.poster.attributes.url"
              class="img-raised w-50"
            />
            <b-icon v-if="!disabled" icon="x-circle-fill" variant="danger" class="h4" role="button"
              style="position: relative; top: calc(-50% + 33px); left: -25px; background: white; border-radius: 60%;" 
              @click="deleteImage = true; showUploadPoster = true; activityDetail.poster = null;"></b-icon>
          </span>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="9"
          label="Chi"
          label-size="sm"
        >
          <!-- <b-form-input :disabled="disabled" size="sm" id="activity-who" v-model="activityDetail.who"></b-form-input> -->
          <b-row>
            <b-col :cols="!disabled ? '11' : '12'">
              <b-form-select v-for="team in activityDetail.associatedTeams" :key="team.id" :disabled="disabled" :id="'activity-who-' + team.id"
                v-model="team.id" size="sm" class="mb-2" @input="setWho()">
                <b-form-select-option v-for="element in teamsDropDown" :key="element.value" :disabled="isValueDisabled(element.value)" :value="element.value">
                  {{ element.text }}
                </b-form-select-option>
                <template #first>
                  <b-form-select-option :value="null">Seleziona...</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col v-if="!disabled" align-self="end" class="mb-2">
              <b-icon icon="plus" @click="addTeam"></b-icon>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-left">
        <span v-if="isAdd">
          <b-button class="btn mr-3" pill size="sm" @click="insertActivity">Salva</b-button>
          <b-button class="btn" pill size="sm" @click="activityDetail = JSON.parse(JSON.stringify(emptyActivityDetail)); $refs.adminTable.clearSelected(); disabled = true; isAdd = false">Annulla</b-button>
        </span>
        <span v-if="activityDetail.id">
          <span v-if="!isModify && !isAdd">
            <b-row>
              <b-col>
                <b-button class="btn" pill size="sm" @click="modifyActivity">Modifica</b-button>
              </b-col>
              <b-col class="text-right">
                <b-button class="btn" variant="danger" pill size="sm" @click="openDeleteActivity()">Elimina</b-button>
              </b-col>
            </b-row>
          </span>
          <span v-if="isModify">
            <b-button class="btn mr-3" pill size="sm" @click="saveActivity">Salva</b-button>
            <b-button class="btn" pill size="sm" @click="cancelModifyActivity">Annulla</b-button>
          </span>
        </span>
      </b-col>
    </b-row>

    <b-modal ref="deleteActivity" centered hide-footer title="Elimina attività" size="sm">
      <b-row class="text-center mb-3"><b-col><span class="content-label">Sei sicuro di voler eliminare l'attività?</span></b-col></b-row>
      <b-row class="text-center">
        <b-col>
          <b-button class="btn btn-dark btn-sm mr-3" @click="confirmDeleteActivity()">Elimina</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-col>
</template>
<script>
import http from "../../http-api";
import { ActivityCategory } from '../../plugins/const.js';
export default {
  name: 'inset',
  bodyClass: 'profile-page',
  components: {
  },
  data() {
    return {
      labelHelp: "Puoi usare le frecce per navigare tra le date",
      labelCurrentMonth: "Mese corrente",
      labelNextMonth: "Mese successivo",
      labelNextDecade: "Decade successiva",
      labelNextYear: "Anno successivo",
      labelPrevDecade: "Decade precedente",
      labelPrevMonth: "Mese precedente",
      labelPrevYear: "Anno precedente",
      labelTodayButton: "Oggi",
      labelResetButton: "Cancella",
      tableResult: [],
      activityFields: [
        { key: "startDate", label: "Data inzio", tdClass: 'align-middle' },
        { key: "endDate", label: "Data fine", tdClass: 'align-middle' },
        { key: "startTime", label: "Ora inzio", tdClass: 'align-middle' },
        { key: "endTime", label: "Ora fine", tdClass: 'align-middle' },
        { key: "name", label: "Nome", tdClass: 'align-middle' },
        { key: "category", label: "Categoria", tdClass: 'align-middle' },
        { key: "who", label: "Chi", tdClass: 'align-middle' },
      ],
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activityDetail: {
        id: null
      },
      emptyActivityDetail: {
        id: null,
        startDate: null,
        endDate: null,
        periodicity: null,
        startTime: null,
        endTime: null,
        name: null,
        category: null,
        location: null,
        description: null,
        background: null,
        poster: null,
        price: null,
        associatedTeams: [ {id: null} ]
      },
      activityBackup: null,
      disabled: true,
      isModify: false,
      isAdd: false,
      teamsDropDown: [],
      showUploadPoster: true,
      backendURL: process.env.VUE_APP_BACKEND_URL,
      modifiedDate: {
        startDate: null,
        endDate: null
      },
      activityCategory: ActivityCategory
    }
  },
  mounted() {
    this.extractTeams();
    this.manageActivity();
  },
  methods: {
    extractTeams: function() {
      http.get('/api/teams?fields[0]=id&fields[1]=name').then(response => {
        for (var i=0; i<response.data.data.length; i++) {
          var team = response.data.data[i];
          var teamDrop = {
            value: team.id,
            text: team.attributes.name
          }
          this.teamsDropDown.push(teamDrop);
        }
      });
    },
    manageActivity: function() {
      http.get('/api/activities?populate=*&sort[0]=startDate&sort[1]=endDate').then(response => {
        this.buildActivitiesTable(response.data.data);
      });
    },
    buildActivitiesTable: function(data) {
      this.tableResult = [];
      for (var i=0; i<data.length; i++) {
        var element = data[i].attributes;
        element.id = data[i].id;
        element.associatedTeams = [];
        element.poster = data[i].attributes.poster.data;
        if (element.teams && element.teams.data && element.teams.data.length > 0) {
          var teams = "";
          for (var j=0; j<element.teams.data.length; j++) {
            if (j>0) 
              teams += ", ";
            teams += element.teams.data[j].attributes.name;
            element.associatedTeams.push({ id: element.teams.data[j].id });
          }
          element.who = teams;
        } else {
          element.associatedTeams.push({ id: null });
        }
        this.tableResult.push(element)
      }
    },
    rowSelected: function (items) {
      if (items.length > 0) {
        this.activityDetail = items[0];
        this.modifiedDate.startDate = this.$func.formatDBDateToDate(this.activityDetail.startDate);
        this.modifiedDate.endDate = this.$func.formatDBDateToDate(this.activityDetail.endDate);
        if (this.activityDetail.poster)
          this.showUploadPoster = false;
        else
          this.showUploadPoster = true;
      } else 
        this.activityDetail = JSON.parse(JSON.stringify(this.emptyActivityDetail));
    },
    addActivity: function() {
      this.modifiedDate = {
        startDate: null,
        endDate: null
      }
      this.isAdd = true;
      this.$refs.adminTable.clearSelected();
      this.disabled = false;
      this.activityDetail = JSON.parse(JSON.stringify(this.emptyActivityDetail));
      this.showUploadPoster = true;
    },
    insertActivity: function() {
      this.activityDetail.startDate = this.$func.formatDatepickerToDBDate(this.modifiedDate.startDate);
      this.activityDetail.endDate = this.$func.formatDatepickerToDBDate(this.modifiedDate.endDate);
      var activity = {
        data: {
          startDate: this.activityDetail.startDate,
          endDate: this.activityDetail.endDate,
          periodicity: this.activityDetail.periodicity,
          startTime: this.activityDetail.startTime,
          endTime: this.activityDetail.endTime,
          name: this.activityDetail.name,
          category: this.activityDetail.category,
          location: this.activityDetail.location,
          description: this.activityDetail.description,
          background: this.activityDetail.background,
          price: this.activityDetail.price,
          totalPrice: this.activityDetail.totalPrice,
          teams: this.activityDetail.associatedTeams
        }
      }
      if (this.activityDetail.poster) {
        const formData = new FormData();
        formData.append('files', this.activityDetail.poster);
        http.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })
        .then(response => {
          activity.data.poster = response.data[0];
          http.post('/api/activities', JSON.stringify(activity), {
            headers: {
              'Authorization': `Bearer ${this.$store.state.token}`,
            }
          }).then(response => {
            this.isAdd = false;
            this.manageActivity();
          });
        })
        .catch(error => {
          // Handle error
        });
      } else {
        http.post('/api/activities', JSON.stringify(activity), {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          this.isAdd = false;
          this.manageActivity();
        });
      }
    },
    saveActivity: function() {
      this.activityDetail.startDate = this.$func.formatDatepickerToDBDate(this.modifiedDate.startDate);
      this.activityDetail.endDate = this.$func.formatDatepickerToDBDate(this.modifiedDate.endDate);
      var activity = {
        data: {
          startDate: this.activityDetail.startDate,
          endDate: this.activityDetail.endDate,
          periodicity: this.activityDetail.periodicity,
          startTime: this.activityDetail.startTime,
          endTime: this.activityDetail.endTime,
          name: this.activityDetail.name,
          category: this.activityDetail.category,
          location: this.activityDetail.location,
          description: this.activityDetail.description,
          background: this.activityDetail.background,
          price: this.activityDetail.price,
          totalPrice: this.activityDetail.totalPrice,
          teams: this.activityDetail.associatedTeams
        }
      }
      if (this.showUploadPoster && this.activityDetail.poster) {
        const formData = new FormData();
        formData.append('files', this.activityDetail.poster);
        http.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        })
        .then(response => {
          this.showUploadPoster = false;
          this.activityDetail.poster = {
            attributes: response.data[0],
            id: response.data[0].id
          }
          activity.data.poster = response.data[0];
          http.put('/api/activities/' + this.activityDetail.id, JSON.stringify(activity), {
            headers: {
              'Authorization': `Bearer ${this.$store.state.token}`,
            }
          }).then(response => {
            this.isModify = false;
          });
        })
        .catch(error => {
          // Handle error
        });
      } else {
        http.put('/api/activities/' + this.activityDetail.id, JSON.stringify(activity), {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          this.isModify = false;
        });
      }
      if (this.deleteImage) {
        http.delete('/api/upload/files/' + this.activityBackup.poster.id, {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`,
          }
        }).then(response => {
          //console.log(response.data);
        });
      }
      this.disabled = true;
      this.deleteImage = false;
    },
    modifyActivity: function() {
      this.activityBackup = JSON.parse(JSON.stringify(this.activityDetail));
      this.disabled = false;
      this.isModify = true;
    },
    cancelModifyActivity: function() {
      this.activityDetail.startDate = this.activityBackup.startDate;
      this.activityDetail.endDate = this.activityBackup.endDate;
      this.activityDetail.periodicity = this.activityBackup.periodicity;
      this.activityDetail.startTime = this.activityBackup.startTime;
      this.activityDetail.endTime = this.activityBackup.endTime;
      this.activityDetail.name = this.activityBackup.name;
      this.activityDetail.category = this.activityBackup.category;
      this.activityDetail.location = this.activityBackup.location;
      this.activityDetail.description = this.activityBackup.description;
      this.activityDetail.background = this.activityBackup.background;
      this.activityDetail.who = this.activityBackup.who;
      this.activityDetail.poster = this.activityBackup.poster;
      this.activityDetail.price = this.activityBackup.price;
      this.activityDetail.totalPrice = this.activityBackup.totalPrice;
      this.activityDetail.associatedTeams = this.activityBackup.associatedTeams;
      if (this.deleteImage)
        this.showUploadPoster = false;

      this.disabled = true;
      this.isModify = false;
      this.deleteImage = false;
    },
    addTeam: function() {
      if (this.activityDetail.associatedTeams[this.activityDetail.associatedTeams.length - 1].id)
        this.activityDetail.associatedTeams.push({ id: null })
    },
    isValueDisabled: function(value) {
      for (var i in this.activityDetail.associatedTeams) {
        var element = this.activityDetail.associatedTeams[i];
        if (element.id == value) {
          return true;
        }
      }
      return false;
    },
    setWho: function() {
      var teams = "";
      for (var i=0; i<this.activityDetail.associatedTeams.length; i++) {
        if (i>0) 
          teams += ", ";
        const index = this.teamsDropDown.findIndex(item => item.value === this.activityDetail.associatedTeams[i].id);
        const name = this.teamsDropDown[index].text;
        teams += name;
      }
      this.activityDetail.who = teams;
    },
    openDeleteActivity: function() {
      this.$func.openModal(this, 'deleteActivity');
    },
    confirmDeleteActivity: function() {
      this.$func.closeModal(this, 'deleteActivity');
      http.delete('/api/activities/' + this.activityDetail.id, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`,
        }
      }).then(response => {
        this.$refs.adminTable.clearSelected();
        this.manageActivity();
      });
    }
  }
};
</script>
<style>
.b-form-datepicker .btn {
  margin: 0;
}
.b-form-datepicker .b-form-date-calendar .b-calendar-grid-body .btn {
  background-color: #fff;
}
.b-form-datepicker .b-form-date-calendar .b-calendar-grid-body .btn.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.b-form-datepicker .b-form-date-calendar .b-calendar-footer .btn.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #fff;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      //paths: ['token', 'role'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
    }),
  ],
  state: {
    token: "",
    role: "public",
    from: "",
    contact: {
      text: ""
    },
    teamFilter: null
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setRole(state, payload) {
      state.role = payload;
    },
    setFrom(state, payload) {
      state.from = payload;
    },
    setContact(state, payload) {
      state.contact = payload;
    },
    setTeamFilter(state, payload) {
      state.teamFilter = payload;
    }
  },
  actions: {
    setToken(context, payload) {
      context.commit('setToken', payload);
    },
    setRole(context, payload) {
      context.commit('setRole', payload);
    },
    setFrom(context, payload) {
      context.commit('setFrom', payload);
    },
    setContact(context, payload) {
      context.commit('setContact', payload);
    },
    setTeamFilter(context, payload) {
      context.commit('setTeamFilter', payload);
    }
  }
})

export default store
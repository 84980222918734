<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" style="background-image: url('../img/logo.png')" to="/"></router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="20"
        trigger="hover"
      >
        <div class="popover-body">
          Home
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <ul class="nav navbar-nav flex-row float-left">
        <li class="nav-item">
          <b-link class="nav-link pr-3" to="/chi-siamo">Chi siamo</b-link>
        </li>
        <li class="nav-item">
          <b-link class="nav-link pr-3" to="/team">Il team</b-link>
        </li>
        <li class="nav-item">
          <b-link class="nav-link pr-3" to="/attivita" :class="[{'active router-link-active': $route.path.indexOf('attivita') > -1 }]">Attività</b-link>
        </li>
      </ul>
      <!-- <drop-down
        tag="li"
        title="Attività"
        icon=""
        class="nav-item ml-1"
      >
        <nav-link to="/attivita">
          Corsi
        </nav-link>
        <nav-link to="/events">
          Eventi
        </nav-link>
      </drop-down> -->
      <!-- <drop-down
              tag="li"
              title="Examples"
              icon="now-ui-icons design_image"
              class="nav-item"
      >
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <a
          class="nav-link btn btn-neutral"
          href="https://www.creative-tim.com/product/vue-now-ui-kit-pro"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>Upgrade to PRO</p>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Metti un mi piace su Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/p/Chandra-61556071804021/"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Seguici su Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/8.chandra.8/"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
      <drop-down v-if="$store.state.role == 'administrator'"
        tag="li"
        title=""
        icon="person-fill"
        class="nav-item"
      >
        <nav-link to="/admin/gestione">
          Gestione
        </nav-link>
        <b-link class="dropdown-item" to="/" @click="$store.commit('setRole', 'public'); $store.commit('setToken', null);">
          Esci
        </b-link>
      </drop-down>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>

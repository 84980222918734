<template>
  <div style="min-height: calc(100vh - 72px);">
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('img/main.jpg')"
      >
      </parallax>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Chi siamo</h2>
            <h5 class="description">
              Chandra è un progetto che vuole unire donne dall'alto potenziale e dalle conoscenze disparate in ambito olistico, 
              capaci di guidare attività volte al benessere: è un collettore di diverse capacità, esperienze e competenze 
              finalizzato alla creazione di una rete di proposte diversificate ma con intenti, etica e passione comuni.
            </h5>
          </div>
        </div>
        <b-img src="img/crescente.jpg" fluid></b-img>
        <!-- <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            caption="Chiropratica"
            text="La chiropratica è una pratica di medicina alternativa, incentrata sulla manipolazione manuale della colonna vertebrale"
            img-src="img/crescente.jpg"
          ></b-carousel-slide>

          <b-carousel-slide 
            caption="Agopuntura"
            text="L'agopuntura è una medicina di origine cinese usata per curare molte malattie, soprattutto il dolore"
            img-src="img/agopuntura.jpg">
          </b-carousel-slide>

          <b-carousel-slide 
            caption="Yoga"
            text="Rappresenta un gruppo di pratiche e discipline fisiche, mentali e spirituali originariamente indiane"
            img-src="img/yoga1-26-2.jpg">
          </b-carousel-slide>

          <b-carousel-slide 
            caption="Yoga"
            text="E' una pratica millenaria che fa bene a corpo, postura, respiro e spiritualità"
            img-src="img/yoga1-119-2.jpg">
          </b-carousel-slide>
        </b-carousel> -->
        <div class="section-who-overview">
          <b-row align-v="center">
            <b-col md="6">
                <b-row align-h="center">
                  <p class="blockquote blockquote-primary">
                    "Il cambiamento non è mai doloroso.
                    Solo la resistenza al cambiamento lo è."
                    <br />
                    <br />
                    <small>-Buddha</small>
                  </p>
                </b-row>
            </b-col>
            <b-col md="5">
              <h3>
                Come nasce Chandra?
              </h3>
              <p>
                Per caso. E' il frutto di un confronto sulle proprie insicurezze, sulle paure e sulle credenze limitanti che ci bloccano nonostante le conoscenze, 
                il potenziale e le capacità. Quante donne ci sono in questa condizione?
              </p>
              <p>
                E se si mettessero insieme per sostenersi a vicenda?
              </p>
              <p>
                E se si mescolassero le competenze e si completassero?
              </p>
              <p>
                E se si sentissero sicure di poter dare tutto ciò che possono (tanto) come vorrebbero fare?
              </p>
              <p>
                Così è nato questo progetto che unisce tante sfumature di femminile diverso e che vuole portare a tutti coloro 
                che vogliono approfittarne la possibilità di conoscere, sperimentare e vivere pratiche che innalzano il livello di consapevolezza e di benessere.
              </p>
              <p>
                Che tu abbia voglia di provare a ritrovare la gioia di esprimerti attraverso i colori, assaporare la libertà che puoi provare danzando, 
                sentirti parte della natura in mezzo a un bosco, lasciarti guidare dall'inspirazione e dall'espirazione su un tappetino di yoga, 
                la cosa essenziale è che tu abbia voglia di “tornare a casa”, a te, fidandoti e affidandoti a ciò che sei.
              </p>
              <p>
                Chandra (che in sanscrito vuol dire Luna) è un progetto che nasce proprio dal grande tema della fiducia in sé stessi e nelle proprie capacità: 
                si prefigge come obiettivo quello del supporto reciproco nel processo di realizzazione, interiore ed esteriore, 
                dei propri talenti; di portare benessere e consapevolezza attraverso pratiche olistiche e attività inerenti. 
              </p>
            </b-col>
          </b-row>
        </div>
      </div>  
    </div>
  </div>
</template>
<script>
export default {
  name: 'who',
  bodyClass: 'profile-page',
  components: {
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
};
</script>
<style></style>
<template>
  <div class="page-header clear-filter" filter-color="blue">
    <div
      class="page-header-image"
      style="background-image: url('img/mani.jpg')"
    ></div>
    <div class="content">
      <div class="container text-primary">
        <div class="col-md-5 ml-auto mr-auto">
          <b-card
            plain
            header="Accedi">
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="E-mail"
              label-for="email"
              label-size="sm"
            >
              <b-form-input size="sm" id="email" v-model.trim="email" @keyup.enter="login"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Password"
              label-for="password"
              label-size="sm"
            >
              <b-input-group>
                <b-form-input size="sm" id="password" v-model.trim="password" :type="passwordType" @keyup.enter="login"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text role="button" @click="passwordType=='password' ? passwordType='text' : passwordType='password'">
                    <b-icon :icon="passwordType=='password' ? 'eye' : 'eye-slash'" />
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <template #footer>
              <b-button class="btn" block pill @click="login">Accedi</b-button>
              <div class="pull-left">
                  <a href="#pablo" class="footer-link">Registrati</a>
              </div>
              <div class="pull-right">
                  <a href="#pablo" class="footer-link">Hai bisogno di aiuto?</a>
              </div>
            </template>
          </b-card>
        </div>
      </div>
    </div>
    <main-footer backgroundColor='black'></main-footer>
  </div>
</template>
<script>
  import MainFooter from '@/layout/MainFooter';
  import http from "../http-api";
  export default {
    name: 'login-page',
    bodyClass: 'login-page',
    components: {
      MainFooter,
    },
    data() {
      return {
        email: null,
        password: null,
        passwordType: 'password'
      }
    },
    mounted() {
    },
    methods: {
      login: function() {
        http
        .post('/api/auth/local', {
          identifier: this.email,
          password: this.password,
        })
        .then(response => {
          // Handle success.
          //console.log('Well done!');
          //console.log('User profile', response.data.user);
          //console.log('User token', response.data.jwt);
          this.$store.commit("setToken", response.data.jwt);
          http
          .get('/api/users/me?populate=role', {
            headers: {
              Authorization: `Bearer ${response.data.jwt}`,
            }
          })
          .then(response => {
            this.$store.commit("setRole", response.data.role.type);
            if (this.$store.state.from)
              this.$router.push({name: this.$store.state.from});
            else
              this.$router.push({name: 'index'});
          })
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
      }
    }
  };
</script>
<style></style>

<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
  font-family: 'Lobster Two', sans-serif !important;
}
#app {
  font-family: 'Lobster Two', sans-serif !important;
}
.card {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}
.courses-tr {
  height: 90px;
  font-size: 1.5em;
  font-weight: 400;
  color: #1b263b;
}
.course-background {
  height: 190px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 5px;
}
body {
  background-image: url('/public/img/background.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-attachment: fixed;
}
.btn[disabled] {
  cursor: default;
}
.btn:not(a):not([disabled]) {
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0,0.5) !important;
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0,0.5) !important;
  box-shadow: 2px 2px 3px rgba(0, 0, 0,0.5) !important;
}
.btn:active:not(a):not([disabled]) {outline:none;-moz-outline:none;background-image: none;-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;position: relative;box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;top: 1px;left:-1px;}
.navbar-brand {
  height: 80px;
  width: 96px;
  background-size: 96px 80px;
}
.modal-content {
  border: none;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
}
.error {
  color: red;
}
.success {
  color: green;
}
.input-error {
  border-color: red !important;
}
.top-alert {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
</style>

<template>
  <div style="min-height: calc(100vh - 72px);">
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('img/main.jpg')"
      >
      </parallax>
    </div>
    <div class="section">
      <div class="container">
        <h2 class="title">Le prossime attività</h2>
        <div class="filter-container">
          <b-row>
            <b-col class="text-left">
              <h4>Filtri</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-left">
              <b-dropdown id="filter-type" text="Tipologia" ref="filterType" class="m-2">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="checkbox-group-filter-type"
                    v-model="activityFilter.type"
                    name="checkbox-group-filter-type"
                  >
                    <b-form-checkbox v-for="team in activityType" :key="team.value" class="mb-1" :value="team.text">
                      <span>{{ team.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
              <b-dropdown id="filter-month" text="Mese" ref="filterMonth" class="m-2">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="checkbox-group-filter-month"
                    v-model="activityFilter.month"
                    name="checkbox-group-filter-month"
                  >
                    <b-form-checkbox v-for="month in monthsDrop" :key="month.value" class="mb-1" :value="month.value">
                      <span>{{ month.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
              <b-dropdown id="filter-category" text="Categoria" ref="filterCategory" class="m-2">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="checkbox-group-filter-category"
                    v-model="activityFilter.category"
                    name="checkbox-group-filter-category"
                  >
                    <b-form-checkbox v-for="category in activityCategory" :key="category.value" class="mb-1" :value="category.text">
                      <span>{{ category.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
              <b-dropdown id="filter-who" text="Tenuto da" ref="filterWho" class="m-2">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="checkbox-group-filter-who"
                    v-model="activityFilter.who"
                    name="checkbox-group-filter-who"
                  >
                    <b-form-checkbox v-for="team in teamsDropDown" :key="team.value" class="mb-1" :value="team.text">
                      <span class="text-nowrap">{{ team.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
              <b-dropdown id="filter-where" text="Dove" ref="filterWhere" class="m-2">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="checkbox-group-filter-where"
                    v-model="activityFilter.where"
                    name="checkbox-group-filter-where"
                  >
                    <b-form-checkbox class="mb-1" value="ONLINE">
                      <span class="text-nowrap">Online</span>
                    </b-form-checkbox>
                    <b-form-checkbox class="mb-1" value="NOONLINE">
                      <span class="text-nowrap">In presenza</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-left">
              <h5 class="m-0">
              <b-form-tag
                v-for="tag in tags.generic"
                @remove="removeFilter('generic', tag)"
                :key="tag.id"
                :title="tag.value"
                variant="default"
                class="mr-1"
              >{{ tag.value }}</b-form-tag>
              <b-form-tag
                v-for="tag in tags.type"
                @remove="removeFilter('type', tag)"
                :key="tag"
                :title="tag"
                variant="default"
                class="mr-1"
              >{{ tag }}</b-form-tag>
              <b-form-tag
                v-for="tag in tags.month"
                @remove="removeFilter('month', tag)"
                :key="tag"
                :title="tag"
                variant="default"
                class="mr-1"
              >{{ months[tag] }}</b-form-tag>
              <b-form-tag
                v-for="tag in tags.category"
                @remove="removeFilter('category', tag)"
                :key="tag"
                :title="tag"
                variant="default"
                class="mr-1"
              >{{ tag }}</b-form-tag>
              <b-form-tag
                v-for="tag in tags.who"
                @remove="removeFilter('who', tag)"
                :key="tag"
                :title="tag"
                variant="default"
                class="mr-1"
              >{{ tag }}</b-form-tag>
              <b-form-tag
                v-for="tag in tags.where"
                @remove="removeFilter('where', tag)"
                :key="tag"
                :title="tag"
                variant="default"
                class="mr-1"
              >{{ tag }}</b-form-tag>
              </h5>
            </b-col>
          </b-row>
        </div>

        <ActivityTable
          :displayCourses="displayCourses"
          :activityFilter="activityFilter"
          :filterOn="filterOn"/>

      </div>
    </div>
  </div>
</template>
<script>
import http from "../http-api";
import ActivityTable from "./ActivityTable.vue";
import { ActivityCategory, ActivityType, MonthsDrop, Months } from '../plugins/const.js';

export default {
  name: 'activity',
  bodyClass: 'profile-page',
  components: {
    ActivityTable
  },
  data() {
    return {
      courseFields: [
        { key: "month", label: "", thStyle: "width: 160px", thClass: "text-center", tdClass: 'align-middle' },
        { key: "period", label: "Quando", tdClass: 'align-middle' },
        { key: "name", label: "Attività", tdClass: 'align-middle' },
        { key: "who", label: "Chi", tdClass: 'align-middle' },
        { key: "location", label: "Dove", tdClass: 'align-middle' },
        { key: "details", label: "", tdClass: 'align-middle' }
      ],
      displayCourses: [],
      selectedCourse: null,
      tags: {
        generic: [],
        who: [],
        category: [],
        type: [],
        where: [],
        month: []
      },
      activityFilter: {
        generic: [],
        who: [],
        category: [],
        type: [],
        where: [],
        month: []
      },
      filterOn: ['who','category','type','location','month'],
      backendURL: process.env.VUE_APP_BACKEND_URL,
      posterURL: null,
      activityCategory: ActivityCategory,
      activityType: ActivityType,
      monthsDrop: MonthsDrop,
      months: Months,
      teamDetail: null,
      teamsDropDown: []
    }
  },
  created() {
    //const filters = this.$route.params.id;
    const filters = this.$store.state.teamFilter;
    this.activityFilter.generic.push({ id: 'old', value: 'Nascondi le attività concluse' });
    var url = '/api/activities?filters[endDate][$gte]=' + this.$func.getTodayDateString() + '&sort[0]=startDate&sort[1]=endDate&populate[teams][fields][0]=id&populate[teams][fields][1]=name&populate[poster]=*';
    /*if (filters) {
      url += '&filters[teams][id][$eq]=' + filters.id;
    }*/
    http.get(url).then(response => {
      this.displayCourses = this.$func.buildCourses(response.data.data);
      if (filters) {
        this.activityFilter.who.push(filters.value);
        this.$store.commit("setTeamFilter", null );
      }
    });
    http.get('/api/teams?fields[0]=id&fields[1]=name').then(response => {
      for (var i=0; i<response.data.data.length; i++) {
        var team = response.data.data[i];
        var teamDrop = {
          value: team.id,
          text: team.attributes.name
        }
        this.teamsDropDown.push(teamDrop);
      }
    });
  },
  watch: {
    'activityFilter.generic': function() {
      this.tags.generic = [];
      for (var i=0; i<this.activityFilter.generic.length; i++) {
        this.tags.generic.push(this.activityFilter.generic[i]);
      }
    },
    'activityFilter.type': function() {
      this.tags.type = [];
      for (var i=0; i<this.activityFilter.type.length; i++) {
        this.tags.type.push(this.activityFilter.type[i]);
      }
    },
    'activityFilter.category': function() {
      this.tags.category = [];
      for (var i=0; i<this.activityFilter.category.length; i++) {
        this.tags.category.push(this.activityFilter.category[i]);
      }
    },
    'activityFilter.month': function() {
      this.tags.month = [];
      for (var i=0; i<this.activityFilter.month.length; i++) {
        this.tags.month.push(this.activityFilter.month[i]);
      }
    },
    'activityFilter.who': function() {
      this.tags.who = [];
      for (var i=0; i<this.activityFilter.who.length; i++) {
        this.tags.who.push(this.activityFilter.who[i]);
      }
    },
    'activityFilter.where': function() {
      this.tags.where = [];
      for (var i=0; i<this.activityFilter.where.length; i++) {
        this.tags.where.push(this.activityFilter.where[i]);
      }
    }
  },
  mounted() {
    //this.displayCourses = this.$func.buildCourses(this.courses);
  },
  methods: {
    loadOldActivities: function() {
      var url = '/api/activities?sort[0]=startDate&sort[1]=endDate&populate[teams][fields][0]=id&populate[teams][fields][1]=name&populate[poster]=*';
      if (this.$route.params.id) {
        url += '&filters[teams][id][$eq]=' + this.$route.params.id;
      }
      http.get(url).then(response => {
        this.displayCourses = this.$func.buildCourses(response.data.data);
      });
    },
    removeFilter: function(type, tag) {
      if (this.$route.params.id && tag.id == 'who') {
        var url = '/api/activities?sort[0]=startDate&sort[1]=endDate&populate[teams][fields][0]=id&populate[teams][fields][1]=name&populate[poster]=*';
        http.get(url).then(response => {
          this.displayCourses = this.$func.buildCourses(response.data.data);
        });
      } else if (tag.id == 'old') {
        this.loadOldActivities();
      }
      for (var i=0; i<this.activityFilter[type].length; i++) {
        var element = this.activityFilter[type][i];
        if (element == tag)
          this.activityFilter[type].splice(i, 1);
      }
    }
  }
};
</script>
<style>
.modal-auto {
  max-width: max-content;
}
/*.filter-container {
  position: -webkit-sticky; /* Safari & IE
  position: sticky;
  top: 68px;
  z-index: 2;
  background: white;
  width: 100%;
}*/
</style>

<template>
  <div style="min-height: calc(100vh - 72px);">
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('img/main.jpg')"
      >
      </parallax>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Il nostro team</h2>
        <div class="team">
          <b-row align-h="center">
            <b-col v-for="team in teams.data" :key="team.id" sm="8" md="6" lg="4" class="mb-4">
              <b-card
                :title="team.attributes.name"
                :img-src="team.attributes.image.data ? backendURL + team.attributes.image.data.attributes.url : 'img/default-avatar.png'"
                img-alt="Image"
                img-top
                img-height="318px"
                tag="article"
                style="width: 20rem;"
                class="h-100"
                body-class="d-flex flex-column"
              >
                <b-card-text style="max-height: 300px; overflow: hidden;"><p class="category text-primary">{{ team.attributes.role }}</p>
                  {{ limitTo(team.attributes.description) }}
                </b-card-text>
                <b-button pill v-if="team.attributes.description.length > 280" size="sm"
                  class="mb-4" @click="showCompleteDescription(team.attributes.description)">Continua a leggere</b-button>
                <b-button variant="primary" class="mt-auto" @click="showActivities(team.id, team.attributes.name)">Prossime attività</b-button>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <b-modal ref="modalBio" centered hide-header hide-footer size="md">
      <p>
        {{ longDescription }}
      </p>
    </b-modal>
  </div>
</template>
<script>
import http from "../http-api";

export default {
  name: 'team',
  bodyClass: 'profile-page',
  components: {
  },
  data() {
    return {
      teams: [],
      backendURL: process.env.VUE_APP_BACKEND_URL,
      longDescription: ""
    }
  },
  created() {
    http.get('/api/teams?populate=*').then(response => {
      this.teams = response.data;
    });
  },
  methods: {
    showActivities: function(id, name) {
      this.$store.commit("setTeamFilter", { id: id, value: name } );
      this.$router.push({ name: 'activity' });
    },
    showCompleteDescription: function(description) {
      this.longDescription = description;
      this.$func.openModal(this, 'modalBio');
    },
    limitTo: function(value) {
      if (!value) return '';
      if (value.length > 280)
        return value.substring(0, 277) + '...';
      else
        return value;
    }
  }
};
</script>
<style></style>

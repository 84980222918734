export const Months = Object.freeze({
  "01": "Gennaio",
  "02": "Febbraio",
  "03": "Marzo",
  "04": "Aprile",
  "05": "Maggio",
  "06": "Giugno",
  "07": "Luglio",
  "08": "Agosto",
  "09": "Settembre",
  "10": "Ottobre",
  "11": "Novembre",
  "12": "Dicembre",
});
export const MonthsDrop = [
  { value: "01", text: "Gennaio" },
  { value: "02", text: "Febbraio" },
  { value: "03", text: "Marzo" },
  { value: "04", text: "Aprile" },
  { value: "05", text: "Maggio" },
  { value: "06", text: "Giugno" },
  { value: "07", text: "Luglio" },
  { value: "08", text: "Agosto" },
  { value: "09", text: "Settembre" },
  { value: "10", text: "Ottobre" },
  { value: "11", text: "Novembre" },
  { value: "12", text: "Dicembre" }
];
export const ActivityCategory = [
  { value: "CREATIVITA", text: "Creatività e libera espressione" },
  { value: "MENTE", text: "Mente corpo e spirito" },
  { value: "NATURA", text: "Natura e benessere" },
  { value: "FEMMINILE", text: "Ritrovare il femminile" }
];
export const ActivityType = [
  { value: "Corso", text: "Corso" },
  { value: "Evento", text: "Evento" }
];
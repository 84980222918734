<template>
  <div>
    <div class="page-header">
      <parallax
        class="page-header-image"
        style="background-image: url('img/main.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Cambia spesso, ma non perderti mai</h1>
          <!-- <div class="text-center">
            <a href="https://www.facebook.com/p/Chandra-61556071804021/" target="_blank" class="btn btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com/8.chandra.8/" target="_blank" class="btn btn-icon btn-round">
              <i class="fab fa-instagram"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Benvenuta Anima!</h2>
            <h5 class="description">
              <p>Ogni istante della nostra vita è un'occasione per riavvicinarci alla nostra essenza più profonda, quella dimenticata e soffocata dai tanti costrutti e 
              credenze portati dalle esperienze di questa e altre esistenze.</p>
              <p>Come ritrovarla?</p>
              <p>Non c'è una strada, non un percorso tracciato, nessun libretto delle istruzioni: il ventaglio di possibilità che ci si apre 
                davanti ogni giorno permette di progredire in questo meraviglioso viaggio e Chandra vuole essere un facilitatore nel cammino.</p>
              <p>Come?</p>
              <p>Proponendo una variegata proposta di attività, eventi, approcci tutti tesi al benessere e al ritrovare la nostra natura più autentica.</p>
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <b-row>
            <b-col md="6">
              <div
                class="image-container image-left"
                style="background-image: url('img/colori.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "La tua visione diventa chiara solo quando guardi dentro il tuo cuore. Chi guarda fuori, sogna. Chi guarda dentro, si sveglia."
                  <br />
                  <br />
                  <small>-Carl Gustav Jung</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container-vertical"
                style="background-image: url('img/armonia.jpg')"
              ></div>
            </b-col>
            <b-col md="5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/albero.jpg')"
              ></div>
              <!-- <h3 class="mt-5">
                Se prima non si riesce a stare bene con se stessi, non si può stare bene in mezzo agli altri
              </h3> -->
              <div class="text-container-right">
                <p>
                  Che tu abbia voglia di provare a ritrovare la gioia di esprimerti attraverso i colori, assaporare la libertà che puoi provare danzando, 
                  sentirti parte della natura in mezzo a un bosco, lasciarti guidare dall'inspirazione e dall'espirazione su un tappetino di yoga, 
                  la cosa essenziale è che tu abbia voglia di “tornare a casa”, a te, fidandoti e affidandoti a ciò che sei. 
                </p>
                <p>
                  Chandra è un progetto che nasce proprio dal grande tema della fiducia in sé stessi e nelle proprie capacità: 
                  si prefigge come obiettivo quello del supporto reciproco nel processo di realizzazione, interiore ed esteriore, 
                  dei propri talenti; di portare benessere e consapevolezza attraverso pratiche olistiche e attività inerenti. 
                </p>
                <p>
                  E' un collettore di diverse capacità, esperienze e competenze finalizzato alla creazione 
                  una rete di proposte diversificate ma con intenti, etica e passione comuni. 
                </p>
                <p>
                  La scintilla che ne ha spinto all'ideazione è stata la valorizzazione delle capacità femminili, 
                  troppo spesso dimenticate o minimizzate. Da qui il nome Chandra, che in sanscrito significa Luna, 
                  simbolo per eccellenza delle fasi che ogni donna attraversa mensilmente all'interno di sé. 
                </p>
              </div>
              <b-row align-h="center">
                <b-col lg="8">
                  <b-button class="btn" block pill size="lg" @click="goTo('who')">
                    Continua a leggere</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="section section-course text-center">
      <div class="container">
        <h2 class="title">Le prossime attività</h2>
        <div class="course">

            <ActivityTable
              :displayCourses="displayCourses"/>

          <b-row class="mt-5">
            <b-col md="4" offset-md="4">
            <b-button class="btn" block pill size="lg" @click="goTo('activity')">
              Scopri tutte le attività</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Il nostro team</h2>
        <div class="team">
          <b-row>
            <b-col md="4" v-for="team in teams" :key="team.id" class="d-flex flex-column h-100">
              <div class="team-player">
                <b-img
                  :src="team.image ? backendURL + team.image.url : 'img/default-avatar.png'"
                  height="100px"
                  width="100px"
                  rounded="circle"
                  class="img-raised"
                />
                <h4 class="name secondary-font">{{ team.name }}</h4>
                <p class="category">{{ team.role }}</p>
                <p class="description" style="max-height: 186px; overflow: hidden">
                  {{ limitTo(team.description) }}
                </p>
                <b-button pill v-if="team.description.length > 280" size="sm"
                  class="mb-4" @click="showCompleteDescription(team.description)">Continua a leggere</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="4" offset-md="4">
            <b-button class="btn" block pill size="lg" @click="goTo('team')">
              Vieni a conoscerci tutti</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Contattaci</h2>
        <p class="description">Tu sei importante per noi.</p>
        <div v-if="showError && v$.$invalid" class="error mb-4">Alcuni campi non sono stati inseriti o contengono dati errati</div>
        <div v-if="messageSended" class="success mb-4">Grazie per averci contattato. Vi risponderemo al più presto!</div>
        <div v-if="messageError" class="error mb-4">E-mail non inviata, si è verificato un errore</div>
        <b-row>
          <b-col md="8" lg="6" class="text-center ml-auto mr-auto">
            <b-input-group size="lg" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="person"></b-icon>
              </b-input-group-prepend>
              <b-form-input size="lg" type="text" v-model="form.firstName" placeholder="Nome..."
                :class="{ 'input-error': showError && v$.form.firstName.$error }"></b-form-input>
            </b-input-group>
            <!-- <fg-input
              class="input-lg"
              placeholder="Nome..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input> -->
            <b-input-group size="lg" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="envelope"></b-icon>
              </b-input-group-prepend>
              <b-form-input size="lg" type="text" v-model="form.email" placeholder="E-mail..."
                :class="{ 'input-error': showError && v$.form.email.$error }"></b-form-input>
            </b-input-group>
            <!-- <fg-input
              class="input-lg"
              placeholder="E-mail..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input> -->
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Lasciaci il tuo messaggio..."
                :class="{ 'input-error': showError && v$.form.message.$error }"
              ></textarea>
            </div>
            <div class="send-button">
              <b-button class="btn" block pill size="lg" @click="sendMessage"
                >Invia il messaggio</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal ref="modalBio" centered hide-header hide-footer size="md">
      <p>
        {{ longDescription }}
      </p>
    </b-modal>
  </div>
</template>
<script>
import { FormGroupInput } from '@/components';
import ActivityTable from "./ActivityTable.vue";
import ActivityDetail from "./ActivityDetail.vue";
import TeamDetail from "./TeamDetail.vue";
import router from '../router';
import http from "../http-api";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [FormGroupInput.name]: FormGroupInput,
    ActivityDetail,
    TeamDetail,
    ActivityTable
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      showError: false,
      displayCourses: [],
      longDescription: "",
      teams: [],
      messageSended: false,
      messageError: false
    };
  },
  validations() {
    return {
      form: {
        firstName: { required },
        email: { required, email },
        message: { required }
      }
    }
  },
  created() {
    //http.get('/api/teams?pagination[page]=1&pagination[pageSize]=3').then(response => {
    http.get('/api/teams/random').then(response => {
      this.teams = response.data;
    });
    http.get('/api/activities?filters[endDate][$gte]=' + this.$func.getTodayDateString() + '&sort[0]=startDate&sort[1]=endDate&populate[teams][fields][0]=id&populate[teams][fields][1]=name&pagination[page]=1&pagination[pageSize]=5&populate[poster]=*').then(response => {
      this.displayCourses = this.$func.buildCourses(response.data.data);
    });
  },
  mounted() {
    //this.displayCourses = this.$func.buildCourses(this.courses);
  },
  methods: {
    goTo: function(page) {
      router.push({name: page});
    },
    sendMessage: function() {
      this.messageSended = false;
      this.messageError = false;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.showError = true;
        /*var alert = {
          message: "Alcuni dei campi obbligatori non sono valorizzati o contengono dati errati.",
          variant: 'danger',
          dismissSecs: 30
        }
        this.$emit('showAlert', alert);*/
      } else {
        var contact = {
          data: {
            name: this.form.firstName,
            email: this.form.email,
            message: this.form.message
          }
        }
        http.post('/api/contacts', JSON.stringify(contact)).then(response => {
          //console.log(response);
          this.messageSended = true;
        }).catch(err => {
          //console.log(response);
          this.messageError = true;
        });
      }
    },
    showCompleteDescription: function(description) {
      this.longDescription = description;
      this.$func.openModal(this, 'modalBio');
    },
    limitTo: function(value) {
      if (!value) return '';
      if (value.length > 280)
        return value.substring(0, 277) + '...';
      else
        return value;
    }
  }
};
</script>
<style>

</style>

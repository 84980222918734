import axios from "axios";

export default axios.create({
  // DECOMMENTARE LA PRIMA RIGA PER I TEST IN LOCALE
  baseURL: process.env.VUE_APP_BACKEND_URL,
  //TST
  //baseURL: "http://docker.ie.local:8485/",
  //PRD
  //baseURL: "http://alibi.ie.local:8480/",
  //timeout: 300000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Authorization": `Bearer ${process.env.VUE_APP_BACKEND_TOKEN}`
  }
});
<template>
  <div style="margin-left: 15px;">
    <b-row :class="centered ? 'justify-content-center' : ''">
      <!-- <b-form-file
        v-model="file"
        placeholder="Seleziona un file o trascinalo qui..."
        drop-placeholder="Trascina il file qui..."
        accept="image/*"
        @change="inputChange"
        browse-text="Sfoglia"
        :ref="'selectFile' + id"
      ></b-form-file> -->
      <div :style="'width: ' + previewWidth + 'px;'">
        <b-button v-if="!disabled" :disabled="image || url || disabled ? true : false" class="btn btn-sm mr-2 mb-2" @click="clickFile()">
          <b-icon icon="file-earmark-image" class="mr-2"></b-icon>Sfoglia...</b-button>
        <input type="file" accept="image/*" :v-model="file" @change="inputChange" ref='selectFile' class="d-none"/>
        <b-button v-if="!disabled" :disabled="(image || url) && !disabled ? false : true" class="btn btn-sm mb-2 float-right" variant="danger" 
          @click="image=null; $emit('delete-image'); $emit('image-cropped', null);">
          <b-icon icon="trash"></b-icon></b-button>
      </div>
    </b-row>
    <b-row :class="centered ? 'justify-content-center' : ''" class="mb-4">
      <preview ref="preview" v-if="image"
        :width="previewWidth"
        :height="previewHeight"
        :image="image"
        :coordinates="coordinates"
      />
      <b-img v-else fluid :src="url && url.attributes ? backendURL + url.attributes.url : url ? url : '/img/default-avatar.png'" :style="'height: ' + previewHeight + 'px;'"></b-img>
    </b-row>
    <b-modal ref="modalPicture" centered hide-footer title="Anteprima" size="lg" @hidden="$refs['selectFile'].value = null; file = null;">
      <cropper
        ref="cropper"
        class="cropper"
        :src="src"
        :stencil-props="{
          aspectRatio: 1
        }"
        @change="change"
      ></cropper>
      <div class="text-right">Dimensioni: {{ cropWidth }} x {{ cropHeight }}</div>
      <b-button class="btn btn-sm" variant="primary" @click="crop"><b-icon icon="scissors" class="mr-2"></b-icon>
        Seleziona
      </b-button>
    </b-modal>
    <!-- <vue-picture-cut
      ref="pictureCut"
      :src="src"
      @on-change="onChange">
      <vue-picture-cut-mask
        :width="width"
        :height="height"
        :is-round="isRound"
        :resize="resize"
        :color="color"
        :border-color="borderColor"
      />
    </vue-picture-cut> -->
    <!-- 
      :magnification="magnification"
      :init-angle="initAngle"
      :msk-option="mskOption"
      :max-pixel="maxPixel"
      :encoder-options="encoderOptions"
      :format="format"
      :rotate-control="rotateControl"
      :menu-position="menuPosition"
      :menu-thickness="menuThickness"
      :background-color="backgroundColor"
    -->
  </div>    
</template>

<script>
  import { Cropper, Preview } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css';
  import http from "../../http-api";
  export default {
    name: 'Picture',
    props: ['minWidth','minHeight','previewWidth','previewHeight','url','id','disabled','centered'],
    data() {
      return {
        backendURL: process.env.VUE_APP_BACKEND_URL,
        showError: false,
        src: null,
        magnification: null,
        initAngle: 0,
        mskOption: null,
        maxPixel: null,
        encoderOptions: null,
        format: '4:3',
        rotateControl: null,
        menuPosition: null,
        menuThickness: null,
        backgroundColor: null,
        blob: null,
        base64: '',
        width: 4,
        height: 5,
        isRound: false,
        resize: true,
        color: 'rgba(0,247,255,0.49)',
        borderColor: '#E600FF',
        coordinates: {
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        },
        image: null,
        canvas: null,
        file: null,
        cropWidth: '',
        cropHeight: ''
      }
    },
    components: {
      Cropper,
		  Preview
    },
    methods: {
      onChange: function(res) {
        this.blob = res.blob;
        this.base64 = res.base64;
      },
      openModal: function (modal) {
        this.$refs[modal].show();
      },
      closeModal: function (modal) {
        this.$refs[modal].hide();
      },
      inputChange: function(e) {
        const file = e.target.files[0];
        this.src = URL.createObjectURL(file);
        let img = new Image();
        img.onload = () => {
          //console.log(img.width);
          //console.log(img.height);
          if (img.width > this.minWidth && img.height > this.minHeight) {
            this.openModal('modalPicture');
          } else if (img.width < this.minWidth || img.height < this.minHeight) {
            var alert = {
              message: "Immagine troppo piccola, l'immagine è " + img.width + " x " + img.height + ". La dimensione minima è " + this.minWidth + " x " + this.minHeight,
              variant: 'danger',
              dismissSecs: 30
            }
            this.$emit('open-alert', alert);
          } else if (img.width == this.minWidth && img.height == this.minHeight) {
            this.coordinates = {
              width: this.minWidth,
              height: this.minHeight,
              left: 0,
              top: 0,
            };
            this.image = img;
          }
        }
        img.src = this.src;
      },
      /*change({coordinates, canvas}) {
        console.log(coordinates, canvas)
      },*/
      change({canvas}) {
        this.cropWidth = canvas.width;
        this.cropHeight = canvas.height;
      },
      crop: function() {
        const { coordinates, canvas, image } = this.$refs.cropper.getResult();
        this.coordinates = coordinates;
        // You able to do different manipulations at a canvas
        // but there we just get a cropped image, that can be used 
        // as src for <img/> to preview result
        if (canvas.width >= this.minWidth && canvas.height >= this.minHeight) {
          this.canvas = canvas.toDataURL('image/jpeg');
          //this.checkImage(image);
          this.closeModal('modalPicture');
          this.$emit('image-cropped', this.canvas);
        } else {
          var alert = {
            message: "Immagine troppo piccola, l'immagine è " + canvas.width + " x " + canvas.height + ". La dimensione minima è " + this.minWidth + " x " + this.minHeight,
            variant: 'danger',
            dismissSecs: 30
          }
          this.$emit('open-alert', alert);
        }
      },
      clickFile: function() {
        //console.log(this.file);
        this.$refs.selectFile.click();
      },
      checkImage(image) {
        var uri = '/ModuloLibreria/entity/form/check-image';
        http.post(uri, this.canvas)
        .then(res => {
          // do something with res
          //console.log("Ho avuto una risposta!");
          //console.log(res);
          if (res.data) {
            //console.log("E' Ok!");
            this.image = image;
            this.closeModal('modalPicture');
            this.$emit('image-cropped', res.data);
            //console.log(res.data);
          } else {
            this.$emit('open-alert', ['Immagine troppo grande',"L'immagine supera i 5MB di dimensione"]);
          }
        })
        .catch(err => {
          console.log(err);
          this.$emit('open-alert', ['Errore',"Si è verificato un errore, si prega di riprovare. Se il problema persiste siete pregati di contattarci."]);
        });
      }
    }
  }
</script>
<style>
  .cropper {
    height: 600px;
    background: #DDD;
  }
</style>
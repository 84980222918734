<template>
  <div style="min-height: calc(100vh - 72px);">
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('../img/main.jpg')"
      >
      </parallax>
    </div>
    <div class="section-team text-center">
      <div class="container mt-5 mb-5">
        <h2 class="title">Contattaci</h2>
        <p class="description">Lasciaci un messaggio o chiedi informazioni sulle nostre attività.</p>
        <div v-if="showError && v$.$invalid" class="error mb-4">Alcuni campi non sono stati inseriti o contengono dati errati</div>
        <div v-if="messageSended" class="success mb-4">Grazie per averci contattato. Vi risponderemo al più presto!</div>
        <div v-if="messageError" class="error mb-4">E-mail non inviata, si è verificato un errore</div>
        <b-row>
          <b-col md="8" lg="6" class="text-center ml-auto mr-auto">
            <b-input-group size="lg" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="person"></b-icon>
              </b-input-group-prepend>
              <b-form-input size="lg" type="text" v-model="form.firstName" placeholder="Nome..."
                :class="{ 'input-error': showError && v$.form.firstName.$error }"></b-form-input>
            </b-input-group>
            <!-- <fg-input
              class="input-lg"
              placeholder="Nome..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input> -->
            <b-input-group size="lg" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="envelope"></b-icon>
              </b-input-group-prepend>
              <b-form-input size="lg" type="text" v-model="form.email" placeholder="E-mail..."
                :class="{ 'input-error': showError && v$.form.email.$error }"></b-form-input>
            </b-input-group>
            <!-- <fg-input
              class="input-lg"
              placeholder="E-mail..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input> -->
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Lasciaci il tuo messaggio..."
                :class="{ 'input-error': showError && v$.form.message.$error }"
              ></textarea>
            </div>
            <div class="send-button">
              <b-button class="btn" block pill size="lg" @click="sendMessage"
                >Invia il messaggio</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import http from "../http-api";
export default {
  name: 'manage',
  bodyClass: 'profile-page',
  components: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      showError: false,
      messageSended: false,
      messageError: false
    }
  },
  validations() {
    return {
      form: {
        firstName: { required },
        email: { required, email },
        message: { required }
      }
    }
  },
  created() {
    this.form.message = this.$store.state.contact.text;
  },
  methods: {
    sendMessage: function() {
      this.messageSended = false;
      this.messageError = false;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.showError = true;
        /*var alert = {
          message: "Alcuni dei campi obbligatori non sono valorizzati o contengono dati errati.",
          variant: 'danger',
          dismissSecs: 30
        }
        this.$emit('showAlert', alert);*/
      } else {
        var contact = {
          data: {
            name: this.form.firstName,
            email: this.form.email,
            message: this.form.message
          }
        }
        http.post('/api/contacts', JSON.stringify(contact)).then(response => {
          //console.log(response);
          this.messageSended = true;
        }).catch(err => {
          //console.log(response);
          this.messageError = true;
        });
      }
    }
  }
};
</script>
<style></style>
